import React, { useEffect, useState } from "react";
import { calculatePrice } from "../../../utils/parseQuotation"

export const TotalCost = ({
  transport,
  profitableness,
  mark,
  discount,
  cost,
}) => {
  const [preCost, setPreCost] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    
    const { total, onlyCost } = calculatePrice({
      profitableness,
      discount,
      cost,
      mark,
      transport
    })

    setPreCost(onlyCost.toFixed(0));
    setTotal(total.toFixed(0));
  });

  return (
    <div className="text-detail">
      <p> <span>Costo producto:</span> $ {preCost} </p>
      <p> <span>Precio de venta:</span> $ {total} </p>
      <p> <span>Ganancia:</span> $ {(total - preCost).toFixed(0)} </p>
    </div>
  );
};
