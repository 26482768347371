import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { SnackbarProvider, useSnackbar } from "notistack";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/core/styles";
import conf from "../../config";
import Copyright from "../../components/common/copyright";
import { Redirect } from "react-router-dom";
import Logo from "../../static/Logo.png";

const useStyles = makeStyles((theme) => {
  const getPropertiesRoot = () => {
    const $root = document.querySelector(":root");
    const color = getComputedStyle($root).getPropertyValue("--primary-color");
    const textColor =
      getComputedStyle($root).getPropertyValue("--bg-color-ligth");
    return {
      backGroundColor: color,
      textColor: textColor,
    };
  };

  const { backGroundColor, textColor } = getPropertiesRoot();

  return {
    container: {
      height: "100vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    card: {
      position: "relative",
      top: -30,
      padding: 30,
      display: "flex",
      flexDirection: "column",
      maxWidth: "450px",
      border: "1px solid #e4e65e",
      borderRadius: 8,
      backgroundColor: "#f7f7f7",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    submit: {
      marginTop: 40,
      padding: 15,
      background: backGroundColor,
      color: textColor,
      fontSize: "1rem",
      "&:hover": {
        color: "#64621b",
      },
    },
    kamehouseLeyend: {
      position: "absolute",
      bottom: 10,
    },
    containerImage: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "& img": {
        objectFit: "cover",
      },
    },
    title: {
      textAlign: "center",
      marginTop: 20,
      color: backGroundColor,
    },
    input: {
      marginTop: 30,
      "& .MuiFormControl-root": {
        padding: 0,
      },
      "& label.Mui-focused": {
        color: backGroundColor,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: backGroundColor,
        },
      },
    },
  };
});

export default function Login() {
  return (
    <SnackbarProvider maxSnack={3}>
      <IntegrationNotistack />
    </SnackbarProvider>
  );
}

function IntegrationNotistack() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loginRoot, setLoginRoot] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const login = (event) => {
    event.preventDefault();
    let data = generateData();
    fetch(`${conf.api_url}/login/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        let resp = await response.json();
        if (resp["username"]) {
          enqueueSnackbar(resp["username"], { variant: "error" });
        }
        if (resp["password"]) {
          enqueueSnackbar(resp["password"], { variant: "error" });
        }
        if (response.status == 401) {
          enqueueSnackbar(resp["detail"], { variant: "error" });
        }
        if (response.status == 200) {
          setPermissions(resp);
        }
      })
      .catch(function (error) {
        console.log("error: ", error);
        enqueueSnackbar("Se generó un error en la autenticación.", {
          variant: "error",
        });
      });
  };

  const generateData = () => {
    const elements = document.getElementById("loginForm").elements;
    let data = {};
    data.username = elements.username.value.trim();
    data.password = elements.password.value.trim();
    return data;
  };

  const setPermissions = (user) => {
    localStorage.name = user.name;
    localStorage.ldap = user.permission;
    setLoginRoot(true);
  };

  return (
    <section className={classes.container}>
      <div className={classes.card}>
        <div className={classes.containerImage}>
          <img height={130} width={300} src={Logo} />
        </div>
        <h4 className={classes.title}>Ingresa tus credenciales</h4>
        <form id="loginForm" className={classes.form} noValidate>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="username"
            className={classes.input}
            label="Correo electrónico"
            name="username"
            autoComplete="username"
            autoFocus
            onKeyPress={(event) => {
              event.key === "Enter" && login(event);
            }}
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            className={classes.input}
            name="password"
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyPress={(event) => {
              event.key === "Enter" && login(event);
            }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={login}
          >
            Ingresar
          </Button>
        </form>
      </div>
      <div className={classes.kamehouseLeyend}>
        <Box>
          <Copyright />
        </Box>
        {loginRoot ? <Redirect to="/cotizaciones" /> : ""}
      </div>
    </section>
  );
}
