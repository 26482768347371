// Libs
import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
// Services
import { ProductsService } from "../../../services/products";
// Hooks
import useDelayInput from "../../../customhooks/DelayInput";
// Material
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

export default function InputFilter({ selectedItem }) {

  const [busySearch, setBusySearch] = useState(false);
  const [checkFindBy, setCheckFindBy] = useState(true);
  const [productService, setProductService] = useState(null);
  const [productList, setProductList] = useState([]);
  const [delayInput, setDelayInput] = useDelayInput("", 1000);

  const handleShearchProduct = async (event) => {
    const value = event.target.value;
    if (!value) return;
    setDelayInput(value);
  };

  /**
   * Manage selected product sending product to parent component
   */
  const handleOnChange = (_, product) => {
    selectedItem(product)
  }

  /**
   * Get products by input text and paginate it
   * @param {*} param
   * @returns
   */
   const getProducts = async (param) => {
    if (!productService) return;
    setBusySearch(true);
    let products = [];
    const promise = checkFindBy
      ? productService.getProductsByName(param)
      : productService.getProductsByReferency_id(param);

    try {
      const {
        data: { results },
        state,
        message,
      } = await promise;
      products = results;
      setProductList(products || []);
    } catch (error) {
      console.log("error: ", error);
      return;
    }
    setBusySearch(false);
  };

  useEffect(() => {
    getProducts(delayInput);
  }, [delayInput]);

  useEffect(() => {
    setProductService(new ProductsService());
  }, []);

  return (
    <>
      <Autocomplete
        id="searchProduct"
        name="searchProduct"
        className="col-md-9 px-0 col-sm-12 product__with-100"
        options={productList}
        noOptionsText="No se encontraron resultados"
        loading={busySearch}
        loadingText="Buscando..."
        onChange={handleOnChange}
        getOptionLabel={(option) => `${option.referency_id} - ${option.name}`}
        renderTags={(options) => {
          return options.map((option) => (
            <div className="w-100 d-flex justify-content-between">
              <div>{`${option.referency_id} - ${option.name}`}</div>
              <img
                src={option.image_sm}
                width="48"
                height="48"
                className="img-circle-search"
              />
            </div>
          ));
        }}
        renderOption={(option) => (
          <>
            <div className="w-100 d-flex justify-content-between">
              <div>{`${option.referency_id} - ${option.name}`}</div>
              <img
                src={option.image_sm}
                width="64"
                height="64"
                className="img-circle-search"
              />
            </div>
          </>
        )}
        onInputChange={handleShearchProduct}
        style={{ width: 300 }}
        // error={errors.image}
        // helperText={errors.image && "Este campo es requerido."}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Buscar por ${checkFindBy ? "Nombre" : "Código"}`}
            variant="standard"
          />
        )}
      />
      <div className="col-md-3 col-sm-12 px-0 align-self-center">
        <Switch
          checked={checkFindBy}
          onChange={() => setCheckFindBy(prev => !prev)}
          name="checkFindBy"
          inputProps={{
            "aria-label": "Elegir si buscar por nombre o código",
          }}
        />
        {checkFindBy ? "Nombre" : "Código"}
      </div>
    </>
  );
}

InputFilter.prototype = {
  selectedItem: PropTypes.func.isRequired
}