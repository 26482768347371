// Libs
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

// Material
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useFetch from "../../common/hooks/usefetch";

const validate = (values) => {
  const errors = {};
  if (!values.subject) {
    errors.subject = true;
  }
  if (!values.message) {
    errors.message = true;
  }
  return errors;
};

export const SendEmail = ({ closeModal, id, client }) => {
  const [loadingSendEmail, errorSendEmail, setSendEmail, SendEmail] = useFetch({
    path: "pdf/create",
    method: "POST",
  });

  const handleSendMail = (values) => {
    // Create a list of emails
    const ccList = values.send_copy.replaceAll(" ", "").split(",");
    const body = {
      quotation_id: id,
      should_send_mail: true,
      subject: values.subject,
      body_message: values.message,
      to: [client.email],
      cc: ccList || [],
    };
    setSendEmail(body);
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
      send_copy: "",
    },
    validate,
    onSubmit: handleSendMail,
  });

  useEffect(() => {
    console.log('useEffect', SendEmail);
    if(SendEmail) {
      closeModal(false)
    }
  }, [SendEmail]);

  return (
    <div className="create-update">
      <div className="create-update-form">
        <div className="title-modal">Enviar cotización</div>
        <form onSubmit={formik.handleSubmit} id="form-send-email">
          <TextField
            required
            id="subject"
            name="subject"
            label="Asunto"
            margin="normal"
            className="col-md-6 col-sm-12"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subject}
            error={formik.errors.subject && formik.touched.subject}
            helperText={
              formik.errors.subject &&
              formik.touched.subject &&
              "Este campo es requerido."
            }
          />
          <TextField
            id="send_copy"
            name="send_copy"
            label="Enviar copia"
            margin="normal"
            className="col-md-6 col-sm-12"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.send_copy}
          />
          <TextField
            id="message"
            name="message"
            multiline
            rowsMax="4"
            label="Observaciones"
            className="col-md-12 col-sm-12"
            margin="normal"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.message && formik.touched.message}
            helperText={
              formik.errors.message &&
              formik.touched.message &&
              "Este campo es requerido."
            }
          />
          <div className="col-12 px-0 d-flex justify-content-end container-button">
            <Button
              variant="contained"
              disabled={loadingSendEmail}
              onClick={() => closeModal(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loadingSendEmail}
              // startIcon={<DeleteIcon />}
            >
              Enviar Correo con la cotización
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
