const config = {
  api_url: 'http://134.122.21.214:8933/api', // Prod
  // api_url: "http://localhost:8933/api",
  api_products: "https://www.mppromocionales.com/images/grandes/",
  EXTENSION_IMAGE: "jpg",
  PROVEEDORES: {
    MPPROMOCIONALES: "MPPROMOCIONALES",
    PRUEBA: "prueba",
  },
  ROWS_FOR_PAGES: 20,
};

export default config;
