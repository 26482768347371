import * as Yup from "yup";
import { ERROR_REQUIRED } from "../../../types";

export const validatorYup = Yup.object({
  quotationDate: Yup.date().required(ERROR_REQUIRED),
  client: Yup.number().required(ERROR_REQUIRED),
  user: Yup.number().required(ERROR_REQUIRED),
  pay_format: Yup.string()
    .required(ERROR_REQUIRED)
    .max(10, "Máximo 10 caracteres"),
  delivery_time: Yup.string()
    .required(ERROR_REQUIRED)
    .max(5, "Máximo 5 caracteres"),
})
