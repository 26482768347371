import React, { useState } from "react";
import { UnitsCost } from "../../make-rates/create/unitsCost";
import { ProductPDF } from "../pdf/productPDF";
import { Product } from "./ProductForm";

export default function ProductForm({ updateProducts, quotation }) {
  const [units, setUnits] = useState(quotation ? quotation.units : []);
  const [products, setProducts] = useState(quotation ? quotation.products : []);
  const [productEdited, setProductEdited] = useState(null);

  const handleAddUnit = (unit) => setUnits(unit);

  const addProduct = (product) => {
    setProducts([...products, product]);
    updateProducts([...products, product]);
  };

  const editProduct = (product) => {
    const index = products.findIndex((item) => item.id === product.id);
    products[index] = product;
    setProductEdited(null);
    setProducts([...products]);
    updateProducts([...products]);
  };

  const handleRemoveProduct = (product) => {
    const newList = products.filter((item) => item.id !== product.id);
    setProducts(newList);
  };

  const handleEditProduct = (product) => {
    setProductEdited(product);
  };

  return (
    <div>
      <UnitsCost
        products={products}
        handleAddUnit={handleAddUnit}
        preUnits={units}
      />
      {/* <ProductComposition> */}
      <Product
        units={units}
        addProduct={addProduct}
        productEdited={productEdited}
        editProduct={editProduct}
      />
      {/* </ProductComposition> */}
      {products.map((product) => (
        <ProductPDF
          key={product.id}
          product={product}
          removeProduct={handleRemoveProduct}
          editProduct={handleEditProduct}
        />
      ))}
    </div>
  );
}