import { v4 as uuidv4 } from 'uuid';

const buildCost = (product, len) => {
  const costs = []
  const prices = []
  len.forEach((_, i) => {
    const individualCost = {
      discount: product[`discount${i}`],
      mark: product[`mark${i}`],
      profitableness: product[`profitableness${i}`],
      transport: product[`transport${i}`],
    }
    const { total } = calculatePrice({
      ...individualCost,
      cost: product.cost, 
    })

    // Assing variables
    costs[i] = individualCost
    prices[i] = total
  })

  return {
    costs,
    prices
  }
}

const extractTotalLen = (product) => {
  const keys = Object.keys(product)
  if(!keys.length) return 0
  const keysLen =  keys.filter(key => key.includes("discount")).length
  return keysLen
}

export const buildProductObject = (product, units, id=null) => {
  const len = extractTotalLen(product)
  const { costs, prices } = buildCost(product, units)
  const productBuilded = {
    image: product.image,
    size: product.size,
    colors: product.colors,
    name: product.name,
    name: product.name,
    prints: product.prints,
    description: product.description,
    material: product.material,
    observation: product.observation,
    inventory: product.inventory,
    cost: product.cost,
    costs,
    prices,
    units,
    id: id || uuidv4()
  }

  return productBuilded
}

export const calculatePrice = ({ profitableness, discount, cost, mark, transport }) => {
  const profitPorcentual = (parseInt(profitableness) || 0) / 100; // profit
  const discountPorcentual = (parseInt(discount) || 0) / 100; // discount

  const auxCost = parseInt(cost) // page price
  const auxMark = parseInt(mark) // Mark input
  const auxTransport = parseInt(transport) // transport input

  const subTotal = (auxCost - (auxCost * discountPorcentual)) + auxMark
  const total = subTotal + (subTotal * profitPorcentual) + auxTransport
  const onlyCost = subTotal + auxTransport
  
  return { total, onlyCost }
}