// Libs
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { useLocation } from "react-router";
// Material
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
// Icons
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// Utils
import constants from "../../../config";
import useSesionLocalStorage from "../../../hooks/useSesionLocalStorage";
import { calculatePrice } from "../../../utils/parseQuotation"
// Components
import ProductForm from "../ProductForm/Product";
import Loader from "../loader";
import AlertDialog from "../confirm";
// Custom styles
import styles from "./styles.module.css";
import useFetch from "../hooks/usefetch";
import { SendEmail } from "../../make-rates/create/sendEmail";
import { validatorYup } from "./validator.js";

let initialValues = {
  quotationDate: new Date(),
  client: "",
  user: "",
  pay_format: "",
  delivery_time: "",
};

const setInitialValues = (dataSuggested) => {
  if (!dataSuggested) {
    return initialValues;
  }

  let user, client;

  if (Number.isInteger(dataSuggested.client)) {
    user = dataSuggested.user;
    client = dataSuggested.client
  }

  return {
    quotationDate: dataSuggested.date_created,
    client: client || dataSuggested.client?.id,
    user: user || dataSuggested.user?.id,
    pay_format: dataSuggested.pay_format,
    delivery_time: dataSuggested.delivery_time,
  };
};

export default function ClientForm() {

  const item = useSesionLocalStorage({
    type: "read",
    value: "name",
    from: "local",
  });

  let location = useLocation();

  const [quotation, setQuotaion] = useState(null);
  const [dataClients, setDataClients] = useState([]);
  const [products, setProducts] = useState([]);
  const [isClosingQuotation, setIsClosingQuotation] = useState(false);
  const [dataUsers, setDataUsers] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [currentId, setCurrentId] = useState(null);
  const [alert, setAlert] = useState({});
  const [alertOpened, setAlertOpened] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState({open: false, option: ""});

  const [loadingCreate, error, setData, res] = useFetch({
    path: "quotation",
    method: "POST",
  });
  const [loadingUpdate, errorUpdate, setUpdateQuotation, resUpdateQuotation] =
    useFetch({
      path: `quotation/${currentId}`,
      method: "PUT",
    });
  const [loadingPDF, errorPDF, setDataPDF, blobPDF] = useFetch({
    path: "pdf/create",
    method: "POST",
    downloader: true,
  });
  const [loadingGet, errorGet, setGetQuotation, resGetQuotation] =
    useFetch({
      path: `quotation/${currentId}`,
      method: "GET",
    });

  /**
   * Get all clients
   * @returns Client[]
   */
  const getClients = async () => {
    try {
      const response = await fetch(`${constants.api_url}/allclient/`);
      const data = await response.json();
      setDataClients(data.results);
    } catch (error) {
      setAlertOpened(true)
      setAlert({
        type: "error",
        message: "Se presento un error, intentelo en unos momentos si persiste contacte con soporte."
      })
    }
  };

  /**
   * Get all Users
   */
  const getUsers = async () => {
    try {
      let response = await fetch(`${constants.api_url}/profile/`);
      let data = await response.json();
      setDataUsers(data.results);
    } catch (error) {
      setAlertOpened(true)
      setAlert({
        type: "error",
        message: "Se presento un error, intentelo en unos momentos si persiste contacte con soporte."
      })
    }
  };

  /**
   * Calculate price for each product
   */
  const calculatePrice_ = (_products) => {
    //TODO: Refactor this n^2 try to less its grade
    for (let i = 0; i < _products.length; i++) {
      const cost = _products[i].cost;
      const prices = [];
      _products[i].costs.forEach((item) => {

        const { total } = calculatePrice({ ...item,cost })

        prices.push(total);
      });
      // Add prices to product
      products[i].prices = prices;
    }

    return products;
  };

  /**
   * Handle submit form
   */
  const handleCustomSubmit = (values, actions) => {
    
    const data = values;
    data.status = "En progreso";
    data.units = products[0]?.units;

    actions.setSubmitting(false); // Avoid reset form

    data.products = calculatePrice_(products);
    setIsLoader(true);
    if (currentId) setUpdateQuotation(data);
    else setData(data);
  };

  const updateProducts = (_products) => {
    const productsJson = JSON.stringify(_products)
    const productObj = JSON.parse(productsJson)
    setProducts(productObj);
  };

  /**
   * Generate PDF and send by EMail this is comment yet work but
   * we need an email account to config
   */
  const openPDF = () => {
    const body = {
      quotation_id: currentId,
      should_send_mail: false,
      subject: "Hello this is a test",
      body_message: "I am Working, I going to travell tomorrow",
      to: ["ccdelgadop@gmail.com"],
    };
    setDataPDF(body);
  };

  /**
   * handle generate pdf
   */
  const handleOpenPDF = () => {
    if (quotation.products.length === products.length) openPDF()
    else setShowConfirmation({open: true, option: "pdf"})
  };

  useEffect(() => {
    if (!dataUsers.length) return;

    if (!quotation) {
      const user = dataUsers.find((user) => user?.user.first_name === item);
      if (user && user.id) {
        initialValues.user = user.id;
      }
    }
    setIsLoader(false);
  }, [dataUsers]);

  useEffect(() => {
    getClients();
    getUsers();
    if (location && location.state) {
      const { selectUpdate: quotation } = location.state;
      setCurrentId(quotation.id);
      setGetQuotation(quotation);
    }
  }, []);

  /**
   * Handle Errors
   */
  useEffect(() => {
    if (error && errorUpdate && errorPDF && errorGet) {
      setAlert({
        type: "error",
        message:
          "Se presento un error, intentelo en unos momentos si persiste contacte con soporte.",
      });
      setAlertOpened(true);
      setIsLoader(false);
    }
  }, [error, errorUpdate, errorPDF, errorGet]);

  /**
   * Handle save quotation
   */
  useEffect(() => {
    if (res?.id) {
      setCurrentId(res.id);
      setQuotaion(res);
      setAlert({
        type: "success",
        message: "Se ha guardado La cotización",
      });
      setAlertOpened(true);
      setIsLoader(false);
    }
  }, [res]);

  /**
   * Handle update quotation
   */
  useEffect(() => {
    if (resUpdateQuotation) {
      setQuotaion(() => resUpdateQuotation);
      setAlert({
        type: "success",
        message: "Se ha actuallizado La cotización",
      });
      setAlertOpened(true);
      setIsLoader(false);
    }
  }, [resUpdateQuotation]);

  /**
   * Handle get quotation
   */
  useEffect(() => {
    if (resGetQuotation) {
      setQuotaion(resGetQuotation);
      setProducts(resGetQuotation.products);
      setIsLoader(false);
    }
  }, [resGetQuotation]);

  /**
   * Handle download file
   */
  useEffect(() => {
    if (blobPDF) {
      const file = window.URL.createObjectURL(blobPDF);
      const a = document.createElement("a");
      a.href = file;
      a.download = `Cotización N° ${currentId}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }, [blobPDF]);

  if (isLoader || loadingCreate || loadingUpdate || loadingPDF || loadingGet) {
    return (
      <div className={styles.containerLoader}>
        <Loader size={70} />
      </div>
    );
  }

  return (
    <div>
      {!isLoader && !loadingCreate && !loadingUpdate && (
        <>
          <Formik
            initialValues={setInitialValues(quotation)}
            validationSchema={validatorYup}
            onSubmit={handleCustomSubmit}
          >
            {({ errors, touched, handleSubmit, getFieldProps, isValid }) => (
              <>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disabled
                      className="col-md-4 col-sm-12"
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      name="quotationDate"
                      label="Fecha de cotización"
                      {...getFieldProps("quotationDate")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    required
                    select
                    label="Cliente"
                    name="client"
                    className="col-md-8 col-lg-6 col-sm-12"
                    margin="normal"
                    {...getFieldProps("client")}
                    error={errors.client && touched.client}
                    helperText={
                      errors.client && touched.client && errors.client
                    }
                    SelectProps={{
                      MenuProps: {
                        className: `${styles.customList}`
                      }
                    }}
                  >
                    {dataClients.map((clients) => (
                      <MenuItem key={clients.id} value={clients.id}>
                        {clients.nit} | {clients.name} | {clients.agent} |{" "}
                        {clients.dependece}{" "}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div>
                  <TextField
                    required
                    select
                    label="Ejecutivo de ventas"
                    name="user"
                    className="col-md-4 col-sm-12"
                    margin="normal"
                    {...getFieldProps("user")}
                    error={errors.user && touched.user}
                    helperText={errors.user && touched.user && errors.user}
                  >
                    {dataUsers.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.user.first_name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    name="pay_format"
                    className="col-md-4 col-sm-12"
                    label="Formato de pago"
                    margin="normal"
                    {...getFieldProps("pay_format")}
                    error={errors.pay_format && touched.pay_format}
                    helperText={
                      errors.pay_format &&
                      touched.pay_format &&
                      errors.pay_format
                    }
                  />
                  <TextField
                    name="delivery_time"
                    className="col-md-4 col-sm-12"
                    label="Tiempo de entrega (Días)"
                    margin="normal"
                    {...getFieldProps("delivery_time")}
                    error={errors.delivery_time && touched.delivery_time}
                    helperText={touched.delivery_time && errors.delivery_time}
                  />
                </div>
                <ProductForm
                  updateProducts={updateProducts}
                  quotation={quotation}
                />
                <div className={styles.submitButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!!Object.keys(errors).length}
                    type="submit"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    {currentId ? "Actualizar cotización" : "Guardar cotización"}
                  </Button>
                  {currentId && (
                    <span className={styles.printButton}>
                      <Button variant="contained" onClick={handleOpenPDF}>
                        Descargar
                        <PictureAsPdfIcon className={styles.printIcon} />
                      </Button>
                    </span>
                  )}
                </div>
              </>
            )}
          </Formik>
          {false && (
            <div className={styles.completeQuotationButton}>
              {currentId && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!currentId}
                  onClick={() => setIsClosingQuotation(true)}
                >
                  {"Terminar cotización"}
                </Button>
              )}
            </div>
          )}
          <Snackbar
            open={alertOpened}
            autoHideDuration={4000}
            onClose={() => setAlertOpened(false)}
            ContentProps={{
              "aria-describedby": "Alerta del sistema",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert variant="filled" severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
        </>
      )}
      {/* No is posible for limit in account */}
      {isClosingQuotation && false && (
        <>
          <SendEmail
            closeModal={() => setIsClosingQuotation(false)}
            id={currentId}
            client={quotation.client}
          />
        </>
      )}
      {/* confirm dialog */}
      <AlertDialog
        open={showConfirmation.open}
        option={showConfirmation.option}
        close={() => setShowConfirmation({open: false, option: ""})}
        confirm={openPDF}
      />
    </div>
  );
}
