import React from 'react'
import PropTypes from 'prop-types'; 

export default function useSesionLocalStorage({ type, value, from}) {

  const where = from === "local" ? localStorage: sessionStorage
  const action = () => type === "read" ? where.getItem(value): where.setItems(value)
  const valueAux = action()
  return valueAux
}

useSesionLocalStorage.PropTypes = {
  type: PropTypes.oneOf(['read', 'write']),
  value: PropTypes.string,
  from: PropTypes.oneOf(['local', 'sesion']),
}
