// Libs
import React from "react"
// Components
import ClientForm from "../../common/ClientForm/ClientForm"

export default function CustomQuotation() {
  return (
    <div className="container-body">
      <div className="title m-0">
        <div className="title-actions">
          <div className="title-text">Cótizar</div>
        </div>
      </div>
      {/* Client form */}
      <ClientForm />
    </div>
  )
}
