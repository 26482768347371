// Libs
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// Utils
import conf from "../../../config";

function useFetch({ path, method, downloader }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoader] = useState(false);
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);

  const runRequest = (params) => {
    setIsLoader(true);

    // Download File
    if (downloader) {
      fetch(`${conf.api_url}/${path}/`, params)
        .then((res) => res.blob())
        .then((blob) => {
          setRes(blob);
          setError(false);
          setIsLoader(false);
        })
        .catch((e) => {
          setIsLoader(false);
          setError(e);
        });
      return;
    }

    fetch(`${conf.api_url}/${path}/`, params)
      .then((response) => response.json())
      .then((response) => {
        setRes(response);
        setError(false);
        setIsLoader(false);
      })
      .catch((e) => {
        setIsLoader(false);
        setError(e);
      });
  };

  useEffect(() => {
    if (!path || !method) return;

    const params = {
      headers: { "Content-Type": "application/json" },
      method,
    };

    if (method === "POST" || method === "PUT") {
      params.body = JSON.stringify(data);
    }

    data && runRequest(params);
  }, [data]);

  return [isLoading, error, setData, res];
}

useFetch.propTypes = {
  path: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
};

export default useFetch;
