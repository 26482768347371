import React, { useState, useEffect } from "react";
import conf from "../../../config";
// custom hooks
import useFetch from "../hooks/usefetch";

import styles from "./style.module.css"

// Components
import { ProductPDF } from "./productPDF";
import Loader from "../loader";

// redux
import { connect } from "react-redux";

import * as quotationActions from "../../../actions/quotationActions";

const buildUnits = (quotation) => {
  const keys = Object.keys(quotation);
  const unitsNumber = keys.filter((_) => !_.indexOf("cost"));
  const units = [];
  for (let index in unitsNumber) {
    index = parseInt(index);
    units.push({
      number: quotation[`unit${index + 1}`],
      unit: quotation[`cost${index + 1}`],
    });
  }
  return units;
};

const getQuotation = async (quotation) => {
  try {
    let response = await fetch(`${conf.api_url}/quotation/${quotation}/`);
    let data = await response.json();

    return data;
  } catch {
    console.log("errrorrrrr");
  }
};

const getQuotationSession = (props) => {
  const idInitial = props.match.params.id;
  const dataString = idInitial
    ? getQuotation(idInitial)
    : JSON.parse(sessionStorage.getItem("quotation"));
  // const dataString = JSON.parse(sessionStorage.getItem('quotation'));
  console.log("dataString: ", dataString);

  return dataString;
};

export const GeneratePDFHook = (props) => {
  console.log("props pdf: ", props);
  const [unitsCost, setUnitsCost] = useState([]);
  const [quotation, SetQuotation] = useState();
  const [loadingPDF, errorPDF, setDataPDF, blobPDF] = useFetch({
    path: "pdf/create",
    method: "POST",
    downloader: true,
  });

  /**
   * Generate PDF 
   */
  const handlePrintPdf = () => {
    const body = {
      quotation_id: quotation.id,
      should_send_mail: false,
    };
    setDataPDF(body);
  };

  useEffect(async () => {
    const data = await getQuotationSession(props);

    !quotation && SetQuotation(data);

    if (!unitsCost.length) {
      const units = buildUnits(data);
      if (units.length) {
        setUnitsCost(units);
      }
    }
  }, []);

  /**
   * Handle download file
   */
  useEffect(() => {
    if (blobPDF) {
      const file = window.URL.createObjectURL(blobPDF);
      const a = document.createElement("a");
      a.href = file;
      a.download = `Cotización N° ${quotation.id}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }, [blobPDF]);

  if (loadingPDF) {
    return (
      <div className={styles.containerLoader}>
        <Loader size={70} />
      </div>
    );
  }

  return (
    <div id="pdf">
      {quotation && (
        <div className="container-pdf">
          <section>
              <div className="header">
                <p className="line-header"></p>
                <img
                  className="image-header"
                  src="https://kaventas.com.co/catalogo2/wp-content/themes/themeKventas/img/logo.png"
                  alt="Logo de kventas"
                  onClick={handlePrintPdf}
                />
              </div>
              <div className="header-pdf col-md-12 col-sm-12 d-flex">
                <div className="greeting-section">
                    <p className="normal-text">Señores:</p>
                    <p className="normal-text"><span class="title-header">{quotation.client.name}</span></p>
                    <p className="normal-text"><b>TEL.</b> {quotation.client.phone}</p>
                    <p class="normal-text"><b>Cotización N° {quotation.id}</b></p>
                    <br/>
                    <p class="normal-text">
                      Atendiendo amablemente su solicitud nos permitimos cotizar lo
                      siguiente:
                    </p>
                </div>
              </div>
          </section>

          <section>
            {quotation.products.map((product) => (
              <ProductPDF product={product} />
            ))}
          </section>
          <br />
          <br />
          <hr />
          <section className="footer">
              <div class="footer-marg"></div>
              <div class="footer-content">
                <b>Contáctanos</b>
                <p><b>Email:</b> kaventas2@gmail.com / <b>Celular:</b> 300 548 8246 - 314 554 1450</p>
                <p><b>Instagram:</b> <a href="https://www.instagram.com/kventas2/">@kaventas2</a> </p>
              </div>
          </section>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (reducers) => {
  console.log("reducers.quotationReducer: ", reducers.quotationReducer);
  return reducers.quotationReducer;
};

const GeneratePDF = connect(mapStateToProps, quotationActions)(GeneratePDFHook);
export { GeneratePDF };
