// Libs
import React, { useState, useEffect, memo } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { PropTypes } from "prop-types";
// Components
import { UnitsCost } from "../../make-rates/create/unitsCost";
// Material
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Tooltip from "@material-ui/core/Tooltip";
// Custom Styles
import style from "./styles.module.css";
import { ERROR_REQUIRED } from "../../../types";
import { buildProductObject } from "../../../utils/parseQuotation";

import { ProductPDF } from "../pdf/productPDF";
import InputFilter from "../InputFilter/InputFilter";
import { TotalCost } from "../../make-rates/create/totalCost";

/**
 * Build validators to Product Form withou units
 * @param {*} units
 * @returns validators to product Form {Yup}
 */
const buildValidators = (units) => {
  const validators = {
    image: Yup.string().required(ERROR_REQUIRED).max(512, "Máximo 512 caracteres"),
    name: Yup.string().required(ERROR_REQUIRED).max(64, "Máximo 64 caracteres"),
    size: Yup.string().required(ERROR_REQUIRED).max(128, "Máximo 128 caracteres"),
    material: Yup.string().required(ERROR_REQUIRED).max(128, "Máximo 128 caracteres"),
    inventory: Yup.string().required(ERROR_REQUIRED).max(64, "Máximo 64 caracteres"),
    colors: Yup.string().required(ERROR_REQUIRED).max(128, "Máximo 128 caracteres"),
    prints: Yup.string().required(ERROR_REQUIRED).max(128, "Máximo 128 caracteres"),
    cost: Yup.number().required(ERROR_REQUIRED),
    description: Yup.string().required(ERROR_REQUIRED).max(308, "Máximo 308 caracteres"),
    observation: Yup.string().required(ERROR_REQUIRED).max(308, "Máximo 308 caracteres"),
  };

  units.forEach((_, i) => {
    validators[`discount${i}`] = Yup.number().required(ERROR_REQUIRED);
    validators[`mark${i}`] = Yup.number().required(ERROR_REQUIRED);
    validators[`transport${i}`] = Yup.number().required(ERROR_REQUIRED);
    validators[`profitableness${i}`] = Yup.number().required(ERROR_REQUIRED);
  });

  return validators;
};

/**
 * Build initial values to Product Form withou units
 * @param {*} units
 * @returns Initial product values {}
 */
const buildInitialValues = (units, productEdited) => {
  const initialValues = {
    image: productEdited ? productEdited.image_group || productEdited.image : "",
    name: productEdited ? productEdited.name : "",
    size: productEdited ? productEdited.size : "",
    material: productEdited ? productEdited.material : "",
    inventory: productEdited ? productEdited.inventory : "",
    colors: productEdited ? productEdited.colors : "",
    prints: productEdited ? productEdited.prints : "",
    cost: productEdited ? productEdited.cost : 0,
    description: productEdited ? productEdited.description : "",
    observation: productEdited ? productEdited.observation : "",
  };

  units.forEach((_, i) => {
    // ?.costs validate in case that selected product from input search
    initialValues[`discount${i}`] = productEdited?.costs
      ? productEdited.costs[i].discount
      : 0;
    initialValues[`mark${i}`] = productEdited?.costs
      ? productEdited.costs[i].mark
      : 0;
    initialValues[`transport${i}`] = productEdited?.costs
      ? productEdited.costs[i].transport
      : 0;
    initialValues[`profitableness${i}`] = productEdited?.costs
      ? productEdited.costs[i].profitableness
      : 0;
  });

  return initialValues;
};

export function Product({ units, addProduct, editProduct, productEdited }) {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEdited, setIsEdited] = useState(false);

  const handleShowForm = () => {
    if (units.length) setShowForm(true);
  };

  /**
   * Selected object from Input
   */
  const handleselectedItem = (product) => {
    setShowForm(true);
    setIsEdited(false)
    setSelectedItem(product);
  };

  /**
   * Save product
   * @param {*} values 
   */
  const handleCustomSubmit = (values) => {
    const produtc = buildProductObject(
      values,
      units,
      productEdited?.id || selectedItem?.id
    );
    !!productEdited ? editProduct(produtc) : addProduct(produtc);
    setShowForm(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    if (!!productEdited && !showForm) {
      setShowForm(true);
      setSelectedItem(productEdited)
      setIsEdited(true)
    }
  }, [productEdited]);

  return (
    <div className={style.section}>
      <div className="sub-title">
        <span className="text">Productos</span>
        <Tooltip
          title={
            units.length
              ? "Agregar producto"
              : "Debes agregar unidades antes de continuar"
          }
        >
          <button
            onClick={handleShowForm}
            className={`${style.buttonIcon} ${
              units.length ? "button-more" : "button-more__disabled"
            }`}
          >
            <AddCircleIcon className="icon-size" />
          </button>
        </Tooltip>
      </div>

      {/* Search by name or code */}
      { showForm && 
        <div className="col-md-12 px-0 d-flex">
          <InputFilter selectedItem={handleselectedItem} />
        </div>
      }

      {showForm && (
        <ProductForm
          selectedItem={selectedItem}
          units={units}
          submit={handleCustomSubmit}
          isEdited={isEdited}
          clearForm
        />
      )}
    </div>
  );
}

function ProductForm({ selectedItem, units, submit, isEdited }) {

  const handleCustomSubmit = (product) => {
    submit(product);
  };

  return (
    <Formik
      initialValues={buildInitialValues(units, selectedItem)}
      validationSchema={Yup.object(buildValidators(units))}
      onSubmit={handleCustomSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        resetForm
      }) => (
        <>
          <TextField
            name="image"
            className="col-lg-3 col-md-4 col-sm-12"
            label="Url de la imagen"
            margin="normal"
            {...getFieldProps("image")}
            error={errors.image && touched.image}
            helperText={touched.image && errors.image}
          />
          <TextField
            name="name"
            label="Nombre"
            className="col-lg-3 col-md-4 col-sm-12"
            margin="normal"
            {...getFieldProps("name")}
            error={errors.name && touched.name}
            helperText={touched.name && errors.name}
          />
          <TextField
            name="size"
            label="Medidas"
            className="col-lg-3 col-md-4 col-sm-12"
            margin="normal"
            {...getFieldProps("size")}
            error={errors.size && touched.size}
            helperText={touched.size && errors.size}
          />
          <TextField
            name="material"
            label="Material"
            className="col-lg-3 col-md-4 col-sm-12"
            margin="normal"
            {...getFieldProps("material")}
            error={errors.material && touched.material}
            helperText={touched.material && errors.material}
          />
          <TextField
            name="inventory"
            label="Inventario"
            className="col-lg-3 col-md-4 col-sm-12"
            margin="normal"
            {...getFieldProps("inventory")}
            error={errors.inventory && touched.inventory}
            helperText={touched.inventory && errors.inventory}
          />
          <TextField
            name="colors"
            label="Colores disponibles"
            className="col-lg-3 col-md-4 col-sm-12"
            margin="normal"
            {...getFieldProps("colors")}
            error={errors.colors && touched.colors}
            helperText={touched.colors && errors.colors}
          />
          <TextField
            name="prints"
            label="Tintas"
            className="col-lg-3 col-md-4 col-sm-12"
            margin="normal"
            {...getFieldProps("prints")}
            error={errors.prints && touched.prints}
            helperText={touched.prints && errors.prints}
          />
          <TextField
            name="cost"
            label="Precio en página"
            className="col-lg-3 col-md-4 col-sm-12"
            margin="normal"
            {...getFieldProps("cost")}
            error={errors.cost && touched.cost}
            helperText={touched.cost && errors.cost}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <TextField
            name="description"
            multiline
            maxRows="4"
            label="Descripción"
            className="col-md-6 col-sm-12"
            margin="normal"
            {...getFieldProps("description")}
            error={errors.description && touched.description}
            helperText={touched.description && errors.description}
          />
          <TextField
            name="observation"
            multiline
            maxRows="4"
            label="Observaciones"
            className="col-md-6 col-sm-12"
            margin="normal"
            {...getFieldProps("observation")}
            error={errors.observation && touched.observation}
            helperText={touched.observation && errors.observation}
          />
          <div className={`${style.section} sub-title`}>
            <span className="text">Valor por unidades</span>
          </div>
          {units.map((unit, index) => (
            <Unit
              key={unit}
              getFieldProps={getFieldProps}
              errors={errors}
              touched={touched}
              unit={unit}
              index={index}
              values={values}
            />
          ))}
          <div className={style.buttons}>
            <div className={style.buttonsAddProduct}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={handleSubmit}
              >
                {isEdited ? "Actualizar Producto": 'Guardar Producto'}
              </Button>
            </div>
            { (
              <Button type="reset" variant="contained" onClick={resetForm}>
                Limpiar
              </Button>
            )}
          </div>
        </>
      )}
    </Formik>
  );
}

function UnitWithOutMemo({
  unit,
  index,
  getFieldProps,
  errors,
  touched,
  values,
}) {
  return (
    <>
      <div className={style.textUnits}>
        <strong>{unit} Unidades</strong>
      </div>
      <div>
        <TextField
          name={`discount${index}`}
          type="number"
          label="Descuento"
          {...getFieldProps(`discount${index}`)}
          error={errors[`discount${index}`] && touched[`discount${index}`]}
          helperText={touched[`discount${index}`] && errors[`discount${index}`]}
          className="col-lg-3 col-md-4 col-sm-12"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <TextField
          name={`mark${index}`}
          type="number"
          label="Precio de marcación (Unidad)"
          {...getFieldProps(`mark${index}`)}
          error={errors[`mark${index}`] && touched[`mark${index}`]}
          helperText={touched[`mark${index}`] && errors[`mark${index}`]}
          className="col-lg-3 col-md-4 col-md-4 col-sm-12"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <TextField
          name={`profitableness${index}`}
          label="Rentabilidad"
          type="number"
          {...getFieldProps(`profitableness${index}`)}
          error={
            errors[`profitableness${index}`] &&
            touched[`profitableness${index}`]
          }
          helperText={
            touched[`profitableness${index}`] &&
            errors[`profitableness${index}`]
          }
          className="col-lg-3 col-md-4 col-sm-12"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <TextField
          name={`transport${index}`}
          label="Transporte unitario"
          type="number"
          {...getFieldProps(`transport${index}`)}
          error={errors[`transport${index}`] && touched[`transport${index}`]}
          helperText={
            touched[`transport${index}`] && errors[`transport${index}`]
          }
          className="col-lg-3 col-md-4 col-sm-12"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <TotalCost
          className="col-lg-3 col-md-4 col-sm-12"
          transport={`${values[`transport${[index]}`]}`}
          profitableness={values[`profitableness${[index]}`]}
          mark={`${values[`mark${[index]}`]}`}
          discount={`${values[`discount${[index]}`]}`}
          cost={`${values["cost"]}`}
        />
      </div>
    </>
  );
}

const Unit = memo(UnitWithOutMemo);

// Example to composition
const ProductComposition = ({ children }) => children;

// PropTypes
Product.propTypes = {
  units: PropTypes.array.isRequired,
  addProduct: PropTypes.func.isRequired,
  productEdited: PropTypes.object,
};
